import { useTranslation } from 'react-i18next';
import Card from '../../Card/Card';
import Button from '../../Button/Button';

import styles from './ComplainDescriptionStep.module.scss';
import { useContext, useEffect, useRef, useState } from 'react';
import { ComplainContext } from '../../../contexts/ComplainContext';

import { ReactComponent as ArrowLeftIcon } from '../../../assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from '../../../assets/icons/arrow-right.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as ImageIcon } from '../../../assets/icons/image.svg';
import { ReactComponent as UploadIcon } from '../../../assets/icons/upload.svg';

import ComplainSelectedItems from '../ComplainSelectedItems/ComplainSelectedItems';
import { getOrderLineItemsSelectedIds } from '../../../utils/getOrderLineItemsSelectedIds';
import { UserContext } from '../../../contexts/UserContext';
import { useParams } from 'react-router-dom';
import { fetchApi } from '../../../utils/fetchApi';
import CardLine from '../../CardLine/CardLine';
import Alert from '../../Alert/Alert';
import ComplainReasonLabel from '../ComplainReasonLabel/ComplainReasonLabel';

export default function ComplainDescriptionStep() {
   const { t } = useTranslation('complain_steps');

   const [reasons, setReasons] = useState<string[]>([]);
   const [description, setDescription] = useState('');
   const [active, setActive] = useState<number>(0);
   const [files, setFiles] = useState<File[]>([]);
   const [showValidationErrors, setShowValidationErrors] = useState(false);

   const fileInputRef = useRef<HTMLInputElement>(null);

   const {
      setStep,
      addClaimReason,
      selectedOrderLines,
      validReasons,
      claimReasons,
   } = useContext(ComplainContext);

   const { lineItems, tickets } = useContext(UserContext);
   let { id, claimId } = useParams();

   const claim = tickets?.claimTickets.find(
      (ticket) => ticket.id.toString() === claimId
   );

   const orderId = claim?.idOfAssociatedDeal ? claim.idOfAssociatedDeal : id;

   if (!orderId || !lineItems) return null;

   const orderLineItems = getOrderLineItemsSelectedIds(
      lineItems[orderId],
      selectedOrderLines
   );

   const currentOrderLineItem = orderLineItems[active];

   const handleReasonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      if (reasons.includes(value)) {
         setReasons(reasons.filter((reason) => reason !== value));
      } else {
         setReasons([...reasons, value]);
      }
   };

   const handleDescriptionChange = (
      e: React.ChangeEvent<HTMLTextAreaElement>
   ) => {
      setDescription(e.target.value);
   };

   useEffect(() => {
      setDescription(
         claimReasons[currentOrderLineItem.id]?.elaboratedReason || ''
      );
      setReasons(
         claimReasons[currentOrderLineItem.id]?.claimErrorReasonValues || []
      );
      setFiles(claimReasons[currentOrderLineItem.id]?.files || []);

      if (fileInputRef.current) {
         fileInputRef.current.value = '';
      }
   }, [currentOrderLineItem]);

   const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newFiles: File[] = [];

      const maxUploadSizeInMB = 15;

      if (event.target.files) {
         Array.from(event.target.files).forEach((file) => {
            if (file.size > maxUploadSizeInMB * 1024 * 1024) {
               alert(t('file_too_large', { size: maxUploadSizeInMB }));
               return;
            }

            if (!file.type.startsWith('image/')) {
               alert(t('file_not_supported'));
               return;
            }

            newFiles.push(file);
         });

         setFiles([...files, ...Array.from(newFiles)]);
      }
   };

   const handleDeleteFile = async (deleteUrl: string) => {
      if (confirm(t('confirm_delete_file'))) {
         await fetchApi(deleteUrl.replace('http://', 'https://'), {
            method: 'post',
         });

         addClaimReason(
            {
               claimErrorReasonValues: reasons,
               elaboratedReason: description,
               files,
               uploadedFiles: claimReasons[
                  currentOrderLineItem.id
               ].uploadedFiles?.filter(
                  (x) => x.actions.deleteAndDetachImage !== deleteUrl
               ),
            },
            currentOrderLineItem.id
         );
      }
   };

   const handleRemoveFile = (index: number) => {
      const newFiles = [...files];
      newFiles.splice(index, 1);
      setFiles(newFiles);
   };

   const handleBack = () => {
      if (active === 0) {
         setStep(1);
         return;
      }

      setActive(active - 1);
   };

   const handleSubmit = (e: React.FormEvent) => {
      if (!isValid()) {
         e.preventDefault();
         setShowValidationErrors(true);
         return;
      } else {
         setShowValidationErrors(false);
      }

      if (!currentOrderLineItem) return;

      e.preventDefault();

      addClaimReason(
         {
            claimErrorReasonValues: reasons,
            elaboratedReason: description,
            files,
            uploadedFiles:
               claimReasons[currentOrderLineItem.id]?.uploadedFiles || [],
         },
         currentOrderLineItem.id
      );

      if (active === orderLineItems.length - 1) {
         setStep(3);
         return;
      }

      setActive(active + 1);
   };

   const isValid = () => {
      return (
         reasons.length &&
         description &&
         (files.length ||
            claimReasons[currentOrderLineItem.id]?.uploadedFiles?.length)
      );
   };

   const currentIndex = orderLineItems.findIndex(
      (x) => currentOrderLineItem.id === x.id
   );

   return (
      <div className={styles.content}>
         <div className={styles.form}>
            <form onSubmit={handleSubmit}>
               <Card
                  title={`${t('fill_the_form')}: ${currentOrderLineItem.name}`}
               >
                  <CardLine>
                     <label className={styles.label} htmlFor='reason'>
                        {t('reason_label')}*
                     </label>

                     <div>
                        {validReasons?.map((reason) => (
                           <label className={styles.check} key={reason}>
                              <input
                                 onChange={handleReasonChange}
                                 checked={reasons.includes(reason)}
                                 type='checkbox'
                                 value={reason}
                              />
                              <ComplainReasonLabel reason={reason} />
                           </label>
                        ))}

                        {showValidationErrors && !reasons.length && (
                           <Alert>{t('reason_required')}</Alert>
                        )}
                     </div>

                     <label htmlFor='description' className={styles.label}>
                        {t('description_label')}*
                     </label>
                     <textarea
                        id='description'
                        onChange={handleDescriptionChange}
                        value={description}
                        placeholder={t('description_placeholder')}
                        rows={4}
                        required
                     ></textarea>
                     {showValidationErrors && !description && (
                        <Alert>{t('description_required')}</Alert>
                     )}

                     <label className={styles.label}>
                        {t('upload_images')}*
                     </label>

                     <label className={styles.upload}>
                        <ImageIcon />
                        <h3>{t('upload_images')}</h3>
                        <input
                           ref={fileInputRef}
                           type='file'
                           accept='image/*'
                           multiple
                           onChange={handleFileChange}
                           required={
                              files.length === 0 &&
                              !claimReasons[currentOrderLineItem.id]
                                 ?.uploadedFiles?.length
                           }
                        />

                        <Button small variant='secondary'>
                           {t('upload_button')}
                           <UploadIcon />
                        </Button>
                     </label>

                     {showValidationErrors &&
                        files.length === 0 &&
                        !claimReasons[currentOrderLineItem.id]?.uploadedFiles
                           ?.length && <Alert>{t('image_required')}</Alert>}

                     <div className={styles.preview}>
                        {claimReasons[
                           currentOrderLineItem.id
                        ]?.uploadedFiles?.map((x, index) => (
                           <button
                              type='button'
                              onClick={() =>
                                 handleDeleteFile(
                                    x.actions.deleteAndDetachImage
                                 )
                              }
                              key={index}
                              className={styles.previewItem}
                           >
                              <img key={index} src={x.tempUrl} alt='' />
                              <CloseIcon />
                           </button>
                        ))}

                        {files.map((file, index) => (
                           <button
                              type='button'
                              key={index}
                              onClick={() => handleRemoveFile(index)}
                              className={styles.previewItem}
                           >
                              <img
                                 key={index}
                                 src={URL.createObjectURL(file)}
                                 alt=''
                              />

                              <CloseIcon />
                           </button>
                        ))}
                     </div>
                  </CardLine>
               </Card>

               <div className={styles.actions}>
                  <Button variant='ghost' onClick={handleBack}>
                     <ArrowLeftIcon />
                     {t('go_back')}
                  </Button>

                  <Button type='submit'>
                     {active === orderLineItems.length - 1
                        ? t('go_to_overview')
                        : t('go_to_next_element')}
                     <ArrowRightIcon />
                  </Button>
               </div>
            </form>
         </div>

         <ComplainSelectedItems
            currentIndex={currentIndex}
            current={currentOrderLineItem.id}
         />
      </div>
   );
}
